// HeaderBox.js
import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// Create a styled Box for the header
const StyledHeaderBox = styled(Box)(({ theme, backgroundImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover', // Ensures the image covers the box
  backgroundPosition: 'center', // Centers the image
  backgroundRepeat: 'no-repeat', // Prevents image repetition
  color: '#fff',
  padding: theme.spacing(4),
  textAlign: 'center',
  position: 'relative', // Ensure image positioning is relative
  borderRadius: '5px', // Add curved corners
  overflow: 'hidden', // Ensure the image fits within the curved corners
  height: '400px', // Set height (adjust as needed)
  [theme.breakpoints.down('sm')]: {
    height: '300px', // Adjust height for smaller screens
    padding: theme.spacing(2),
  },
}));

// HeaderBox Component
const HeaderBox = ({ backgroundImage, children }) => {
  return (
    <StyledHeaderBox backgroundImage={backgroundImage}>
      {children}
    </StyledHeaderBox>
  );
};

export default HeaderBox;
