import React from 'react';
import { Typography, Container, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css'; // Import styles for the carousel
import imgPath from '../assets/img/dhanHO.jpeg';

const AboutPage = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    largeDesktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const SampleImage = styled('img')({
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  });
  


  return (
    <Container>
      <ResponsiveAppBar />
          <Paper>
            <SampleImage src={imgPath} alt='label' />
            <Typography variant="caption" align="center"></Typography>
          </Paper>
       

      <Typography variant="h4" component="h1" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to our company! We are committed to providing the best service to our customers.
      </Typography>
      <Typography variant="body1" paragraph>
        Our team consists of experienced professionals dedicated to ensuring client satisfaction.
      </Typography>
      <Typography variant="body1" paragraph>
        We believe in quality, integrity, and hard work. Our mission is to make a positive impact in our community and industry.
      </Typography>
      <Typography variant="body1" paragraph>
        Thank you for visiting our website. We look forward to serving you!
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <SampleImage 
              src="https://images.unsplash.com/photo-1506784983877-682f3f4e00f6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3NDB8MHwxfGFsbHwxfHx8fHx8fHwxNjg1NDA2NjI3&ixlib=rb-4.0.3&q=80&w=400" 
              alt="Teamwork"
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3}>
            <SampleImage 
              src="https://images.unsplash.com/photo-1511159692422-0457a733d1e1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMTc3NDB8MHwxfGFsbHwxfHx8fHx8fHwxNjg1NDA2NjI3&ixlib=rb-4.0.3&q=80&w=400" 
              alt="Company Vision"
            />
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h5" component="h2" gutterBottom style={{ marginTop: '20px' }}>
        Our Values
      </Typography>
      <Typography variant="body1" paragraph>
        - Customer Focus: We prioritize our customers' needs in every decision we make.<br />
        - Integrity: We conduct our business with honesty and transparency.<br />
        - Innovation: We encourage creative solutions and embrace change.<br />
        - Collaboration: We work together to achieve common goals.<br />
      </Typography>
      <footer className="footer">
          <p>Dhanalakshmi Hire Purchase & Leasing Ltd | Non-Banking Financial Company</p>
          <p>&copy; 2024 Dhanalakshmi Hire Purchase & Leasing Ltd. All rights reserved.</p>
          <p>Powered By :IT DEPARTMENT DHANFIN</p>
        </footer>
    </Container>
  );
};

export default AboutPage;
